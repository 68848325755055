<template>
  <v-row class="ml-0 pl-md-0" justify="center" justify-md="start" dense no-gutters>
    <v-col cols="12">
      <p>{{ t('enter_your_postcode_for_waste') }}</p>
    </v-col>
    <v-col cols="12">
      <v-menu
        id="menu-zip-change"
        v-model="zipDropdown"
        role="combobox"
        aria-expanded="false"
        arial-controls="zip-input-change-postcode"
        transition="slide-y-transition">
        <template #activator="{ props }">
          <v-text-field
            id="zip-input-change-postcode"
            v-model="postalCodeString"
            width="100%"
            variant="outlined"
            :placeholder="t('your_zip')"
            class="mb-3 mt-4 hero-input"
            v-bind="props"
            :error="errorMarker"
            @focus="resetPayloadZipcode"
            @input="checkZipInput"
            @keydown.enter.prevent="setZipcode" />
        </template>
        <v-list v-if="cities.length > 0">
          <v-list-item v-for="item in cities" :key="item?.zip" @click="selectZipCode(item)">
            <v-list-item-title>
              {{ `${item?.zip}, ${item?.city}` }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
  </v-row>
  <v-row dense>
    <v-col cols="12" class="mt-4">
      <v-tooltip top :disabled="!wrongZipcode">
        <template #activator="{ props }">
          <div v-bind="props">
            <ButtonPrimary width="100%" min-height="80px" @click="setZipcode">
              {{ t('show_containers') }}
            </ButtonPrimary>
          </div>
        </template>
        <div class="pa-3">{{ t('enter_valid_zip') }}</div>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import type { City, CityApiResponse } from '~/types/types'

const { t } = useI18n()

const wrongZipcode = ref(true)
const postalCodeString = ref('')
const postalCode = ref('')
const community = ref<number | null>(null)
const cities = ref<CityApiResponse[]>([])
const city = ref('')
const { checkZipcode } = useApiWrapper()
const errorMarker = ref(false)
const sidebarStore = useSidebarStore()
const emit = defineEmits(['zipCodeEntered'])
const zipDropdown = ref(false)

const checkZipInput = () => {
  if (postalCodeString.value.length < 3 || postalCodeString.value.length > 5) {
    wrongZipcode.value = true
    return
  }
  checkZipcode(postalCodeString.value).then((response: any) => {
    if (!response || response === null) {
      return
    } else {
      cities.value = response as CityApiResponse[]
      if (cities.value.length < 1) {
        wrongZipcode.value = true
        errorMarker.value = true
      } else {
        errorMarker.value = false
        if (cities.value.length === 1 && postalCode.value !== cities.value[0]?.zip) {
          selectZipCode(cities.value[0])
          zipDropdown.value = false
        } else {
          zipDropdown.value = true
        }
      }
    }
  })
}

const selectZipCode = (response: CityApiResponse) => {
  wrongZipcode.value = false
  errorMarker.value = false
  postalCode.value = response?.zip as string
  city.value = response?.city as string
  community.value = response?.community as number
  postalCodeString.value = `${response?.zip}, ${response?.city}`
}
const resetPayloadZipcode = () => {
  postalCode.value = ''
  city.value = ''
  community.value = null
  postalCodeString.value = ''
  wrongZipcode.value = true
  errorMarker.value = false
}

const setZipcode = () => {
  if (wrongZipcode.value) return
  const selectedCity: City = {
    zip: postalCode.value,
    name: city.value,
    community: community.value as number,
  }
  emit('zipCodeEntered', selectedCity)
  setTimeout(() => {
    resetPayloadZipcode()
    sidebarStore.closeSidebar()
  }, 500)
}
</script>
<style lang="scss" scoped>
.hero-input :deep(input) {
  text-align: center !important;
}
.v-tooltip,
.v-tooltip * {
  z-index: 10001 !important;
}
</style>
